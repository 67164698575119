// @flow
import * as React from 'react';

import IconFileBase from './IconFileBase';
import type { Icon } from '../flowTypes';

const IconFileVideo = (props: Icon) => (
    <IconFileBase {...props} baseClassName="icon-file-video">
        <path d="M25 27H7V5h13l5 5v17z" fill="#fff" />
        <path
            d="M20 4H7a1 1 0 0 0-1 1v22a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V9.89zm5 23H7V5h13v4a1 1 0 0 0 1 1h4z"
            fill="#22a7f0"
        />
        <path d="M12.43 21.8c-.24.13-.43 0-.43-.27v-8c0-.28.2-.4.43-.27l7.14 4a.25.25 0 0 1 0 .48z" fill="#22a7f0" />
    </IconFileBase>
);

export default IconFileVideo;
