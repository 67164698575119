// @flow
import React from 'react';

import './Crawler.scss';

export type Props = {
    className?: string,
    size?: 'small' | 'medium' | 'large' | 'default',
};

const LoadingIndicator = ({ className = '', size = 'default' }: Props) => (
    <div className={`crawler ${className} is-${size}`}>
        <div />
        <div />
        <div />
    </div>
);

export default LoadingIndicator;
