// @flow
import * as React from 'react';

import IconFileBase from './IconFileBase';
import type { Icon } from '../flowTypes';

const IconFilePhotoshop = (props: Icon) => (
    <IconFileBase {...props} baseClassName="icon-file-photoshop">
        <path d="M25 27H7V5h13l5 5v17z" fill="#fff" />
        <path
            d="M20 4H7a1 1 0 0 0-1 1v22a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V9.89zm5 23H7V5h13v4a1 1 0 0 0 1 1h4z"
            fill="#22a7f0"
        />
        <path
            d="M10.79 14.85v2.67h1.4a1.25 1.25 0 0 0 1.42-1.32 1.25 1.25 0 0 0-1.42-1.35zm-.79-.7h2.4a2 2 0 0 1 2 2v.05a2 2 0 0 1-2 2h-1.61v2.26H10zM15.88 18.83a2.09 2.09 0 0 0 2.33 1.81c1.45 0 2.37-.74 2.37-1.91 0-.93-.53-1.44-1.84-1.77l-.66-.18c-.87-.22-1.22-.51-1.22-1a1.29 1.29 0 0 1 1.4-1.06 1.3 1.3 0 0 1 1.44 1h.8A2 2 0 0 0 18.31 14c-1.34 0-2.24.73-2.24 1.81 0 .9.5 1.42 1.64 1.71l.81.21c.87.22 1.27.56 1.27 1.1a1.36 1.36 0 0 1-1.48 1.09 1.49 1.49 0 0 1-1.63-1.09z"
            fill="#22a7f0"
        />
    </IconFileBase>
);

export default IconFilePhotoshop;
