// @flow
import * as React from 'react';

import AccessibleSVG from '../accessible-svg';

type Props = {
    className?: string,
    color?: string,
    height?: number,
    /** A text-only string describing the icon if it's not purely decorative for accessibility */
    title?: string | React.Element<any>,
    width?: number,
};

const IconSadCloud = ({ className = '', color = '#979797', height = 64, title, width = 64 }: Props) => (
    <AccessibleSVG
        className={`icon-sad-cloud ${className}`}
        height={height}
        title={title}
        viewBox="0 0 1224 792"
        width={width}
    >
        <g transform="translate(1 1)">
            <path
                className="fill-color"
                d="M273.7 788.9c-142 0-257.6-116.5-257.6-259.7 0-140.3 110.9-255 249-259.6
                    4.6-70.2 33.9-135.4 83.6-185.3C402.8 29.7 475-.3 551.9-.3c56.8 0 110.7 11.3 156 32.7 43.7 20.7
                    78.4 50.5 101.3 86.9 7.8-1.9 16.2-2.9 25.1-2.9 43.4 0 97.3 23.8 128.2 56.6l.2.2c23.9 26 41.2
                    59.1 48.9 93.1 4.1 18.3 5 35.1 2.8 49.2 15.8 3.5 32.3 9.8 48.9 18.7 25.1 13.5 49.9 32.7 71.7
                    55.4 48.9 51.2 77 113.7 77 171.7 0 61.1-23.4 118.4-66 161.3-42.5 42.8-99.1 66.4-159.5
                    66.4l-712.8-.1zm0-479.5c-120 0-217.6 98.6-217.6 219.7 0 121.2 97.6 219.7 217.6 219.7h712.8c49.6
                    0 96.2-19.4 131.1-54.6 35.1-35.3 54.4-82.6 54.4-133.1 0-47.7-24-100.2-65.9-144-37.8-39.5-84.7-65-119.6-65-7.9
                    0-15.1-4.6-18.3-11.9-3.2-7.2-1.9-15.6 3.4-21.5 2.8-3.1 6.8-18 1-43.8-6.1-26.9-20.4-54.1-39.3-74.8-23.2-24.6-66.7-43.9-99-43.9-9.9
                    0-18.3 1.7-24.8 5.2-4.7 2.5-10.3 3-15.3 1.4-5.1-1.6-9.3-5.2-11.8-9.9-18.2-35.5-49.9-64.7-91.6-84.4-40-18.9-88-28.9-138.9-28.9-66.2
                    0-128.3 25.8-175 72.8-46.8 47.1-72.6 109.9-72.6 176.9 0 11-9 20-20 20h-10.6z"
                fill={color}
            />
            <path
                className="fill-color"
                d="M442.7 585.5c-2.9 0-5.9-.6-8.7-2-10-4.8-14.1-16.7-9.3-26.7 15.4-32 39.7-59.1
                    70.3-78.1 31-19.3 66.9-29.5 104-29.5 75.5 0 141.4 40 176.2 107.1 5.1 9.8 1.3 21.9-8.5 27-9.8
                    5.1-21.9 1.3-27-8.5-27.8-53.6-80.3-85.5-140.6-85.5-29.6 0-58.2 8.1-82.8 23.5-24.1 15.1-43.3
                    36.3-55.4 61.5-3.6 7-10.7 11.2-18.2 11.2z"
                fill={color}
            />
            <circle className="fill-color" cx="496.3" cy="351.5" fill={color} r="32.1" />
            <circle className="fill-color" cx="688.9" cy="351.5" fill={color} r="32.1" />
        </g>
    </AccessibleSVG>
);

export default IconSadCloud;
