// @flow
import * as React from 'react';

import IconFileBase from './IconFileBase';
import type { Icon } from '../flowTypes';

const IconFileDwg = (props: Icon) => (
    <IconFileBase {...props} baseClassName="icon-file-dwg">
        <path
            d="M20 4H7a1 1 0 0 0-1 1v22a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V9.89zm5 23H7V5h13v4a1 1 0 0 0 1 1h4z"
            fill="#0696d7"
        />
        <path d="M18 17v-2h-2v-4h-1v4h-2v2H9v1h4v2h2v4h1v-4h2v-2h4v-1zm-1 2h-3v-3h3z" fill="#0696d7" />
    </IconFileBase>
);

export default IconFileDwg;
